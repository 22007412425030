import React from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { ESnackbarStyle, ModalState, EAdminRoles, TableAdmin, School } from 'shared/types';
import { closeModal, setIsDataLoadingParameter } from 'services/store/reducers/modalReducer';
import { Button, Input, Modal, Select } from 'antd';
import { getIn, useFormik } from 'formik';
import * as Styled from './styles';
import { getFieldError } from 'utils/error-utils';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { validation } from 'services/validation';
import { addAdmin, updateAdmin } from 'services/api/adminsService';
import { openNotification } from 'utils/notification-utils';
import { FIELDS } from './constants';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  school: string | undefined;
  role: EAdminRoles;
};

type Props = {
  fetchAdmins: () => Promise<void>;
  admin?: TableAdmin;
};

const AdminActionsModal: React.FC<Props> = ({ fetchAdmins, admin }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);
  const schools = useAppSelector((state): School[] => state.schools.allSchools);
  const school = schools.find((item) => item.Name === admin?.school?.name);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  const initialValues: FormValues = {
    email: admin?.email || '',
    firstName: admin?.firstName || '',
    lastName: admin?.lastName || '',
    password: '',
    school: school ? `${school.id}` : undefined,
    role: admin?.role || EAdminRoles.SCHOOL_ADMIN
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      dispatch(setIsDataLoadingParameter(true));
      try {
        const school = schools.find((item): boolean => item.id === values.school)!;
        const { firstName, lastName, password, email, role } = values;
        if (school.Domain && school.Domain.length !== 0) {
          !!admin
            ? await updateAdmin(admin.id, { email, profile: { firstName, lastName }, school })
            : await addAdmin({
                firstName,
                lastName,
                password,
                email,
                school,
                role
              });
        } else {
          openNotification(ESnackbarStyle.ERROR, 'Provide a domain for school');
          return;
        }
        await fetchAdmins();
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        dispatch(setIsDataLoadingParameter(false));
        dispatch(closeModal());
      }
    },
    initialValues,
    validationSchema: !!admin ? validation.EDIT_ADMIN : validation.CREATE_ADMIN,
    enableReinitialize: true
  });

  const handleSchoolFieldChange = (value: string): void => {
    formik.setFieldValue('school', value);
  };

  return (
    <Modal
      visible={isModalOpened}
      title={!!admin ? 'Edit School Admin' : 'Add New School Admin'}
      onCancel={handleCancel}
      footer={[
        <Button
          key='save'
          type={'primary'}
          onClick={(): void => formik.handleSubmit()}
          disabled={isModalDataLoading}
        >
          Save
        </Button>
      ]}
    >
      <form onSubmit={formik.handleSubmit}>
        {FIELDS.map(
          (field): JSX.Element => (
            <Styled.InputContainer key={field.fieldName}>
              <Input
                name={field.fieldName}
                value={getIn(formik.values, field.fieldName)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={field.placeholder}
                disabled={isModalDataLoading}
                allowClear
              />
              {getFieldError(formik, field.fieldName)}
            </Styled.InputContainer>
          )
        )}
        {!admin && (
          <Styled.InputContainer>
            <Input.Password
              name={'password'}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={'Password'}
              allowClear
              iconRender={(visible): JSX.Element =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              disabled={isModalDataLoading}
              autoComplete='new-password'
            />
            {getFieldError(formik, 'password')}
          </Styled.InputContainer>
        )}
        {!(admin?.role === EAdminRoles.SUPER_ADMIN) && (
          <Styled.InputContainer>
            <Select
              placeholder='School'
              allowClear
              value={formik.values.school}
              style={{ width: '100%' }}
              onChange={handleSchoolFieldChange}
              disabled={isModalDataLoading}
            >
              {schools.map(
                (item): JSX.Element => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.Name}
                  </Select.Option>
                )
              )}
            </Select>
            {getFieldError(formik, 'school')}
          </Styled.InputContainer>
        )}
      </form>
    </Modal>
  );
};

export default AdminActionsModal;
