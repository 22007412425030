export const YOUTUBE_LINK_COLUMNS = [
  {
    title: 'Video name',
    dataIndex: 'name',
    key: 'name',
    sortKey: 'name',
    ellipsis: true,
    sorter: true,
    width: 350
  },
  {
    title: 'Video link',
    dataIndex: 'link',
    key: 'link',
    sortKey: 'link',
    ellipsis: true,
    sorter: true,
    width: 350,
    render: (value: string): JSX.Element => (
      <>
        <div data-label='Video link'>
          <a
            href={value}
            target='_blank'
            rel='noopener noreferrer'
            onClick={(e) => e.stopPropagation()}
          >
            {value}
          </a>
        </div>
      </>
    )
  }
];

export const YOUTUBE_LINK_REGEXP = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
