import moment from 'moment';
import { Admin, TableSchoolFile } from 'shared/types';

export const parseISODateFormat = (date: string, formatTime = 'MM/DD/YYYY'): string => {
  const parsedDate = moment.parseZone(date);
  return parsedDate.format(formatTime);
};

export const secondsTransform = (seconds: number): string => {
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
  const hDisplay = h > 0 ? (h < 10 ? `0${h}` : h) : '00';
  const mDisplay = m > 0 ? (m < 10 ? `0${m}` : m) : '00';
  const sDisplay = s > 0 ? (s < 10 ? `0${s}` : s) : '00';

  return `${dDisplay} ${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const modifyFileDate = (files: TableSchoolFile[], currentUser: Admin | null) => {
  const modifiedSchoolFiles = files.map((item): TableSchoolFile & { key: string } => {
    const { Key, LastModified } = item;
    return {
      key: Key,
      Key,
      LastModified: moment
        .utc(LastModified)
        .utcOffset(currentUser?.timezone ?? 0)
        .format(`[uploaded] M/d/YY [at] H:mma [GMT${currentUser?.timezone ?? 0}]`)
    };
  });
  return modifiedSchoolFiles;
};
export const DATE_FORMAT = 'MM/DD/YYYY h:mmA';
